*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: content-box; }

html {
  /* 62.5% of 16px browser font size is 10px, now 1rem is 10px */
  font-size: 62.5%; }

body {
  box-sizing: border-box;
  margin: 0 auto; }

a {
  text-decoration: none;
  outline: 0;
  color: #272b2f; }

@media screen and (min-width: 768px) {
  body {
    max-width: 120rem; } }

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 1.6rem; }

.link {
  font-weight: 400;
  color: #a7a6a6;
  display: inline-block;
  background: linear-gradient(#a7a6a6, #a7a6a6) center 2.5rem;
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition: all 0.45s; }
  .link:hover {
    background-size: 100% 1px; }

.article {
  padding: 5rem 2rem 0 2rem;
  max-width: 80rem;
  position: relative;
  min-height: 44rem;
  margin: 3rem auto; }
  .article__nogallery {
    position: relative;
    min-height: 44rem; }
    .article__nogallery .title {
      padding: 0;
      margin: 0; }
    .article__nogallery .navigation__next {
      margin-left: auto;
      position: static; }

.paragraph {
  font-size: 1.7rem;
  font-weight: 100;
  line-height: 3rem;
  margin-bottom: 3rem; }

.quote {
  color: #a1a1a1;
  font-weight: 200;
  font-size: 2.2rem;
  margin-top: 6rem;
  line-height: 170%;
  font-style: italic;
  position: relative;
  padding-left: 5rem; }
  .quote::before {
    content: '\f10d';
    font-weight: 100;
    font-family: 'FontAwesome';
    font-size: 3.5rem;
    position: absolute;
    left: 0;
    top: 0;
    color: #ddd;
    font-style: normal; }

.title {
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 4.5rem; }

.date {
  color: #999;
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  margin: 1rem 0 6rem 0; }

@media screen and (min-width: 768px) {
  .article {
    padding: 0 20rem;
    margin-bottom: 10rem; }
    .article__nogallery {
      max-width: none; }
    .article.book {
      padding: 0; } }

.footer {
  margin: 2rem;
  padding-top: 1rem;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dotted #7c7c7c;
  color: #a7a7a7;
  font-weight: 100; }

.footer__copy {
  margin-right: auto; }

.footer__mail {
  background: url("../img/mail.png") no-repeat center;
  width: 4rem;
  height: 3rem;
  background-size: contain; }
  .footer__mail:hover {
    background: url("../img/mail_hover.png") no-repeat center;
    width: 4rem;
    height: 3rem;
    background-size: contain; }

.footer__fb {
  fill: #a7a7a7; }
  .footer__fb:hover svg {
    fill: #7c7c7c; }

.topmenu {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  padding: 3rem 2rem; }

.topmenu__list {
  align-items: center;
  list-style-type: none;
  font-size: 1.2rem;
  justify-content: flex-end;
  letter-spacing: 1px;
  color: #272b2f;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.605);
  width: 100%;
  z-index: 1;
  display: none;
  position: absolute;
  top: 8.35rem;
  left: 0; }

.topmenu__link {
  display: none;
  align-items: center;
  position: relative;
  padding: 2rem;
  color: #fff;
  justify-content: center; }

.topmenu__logo {
  margin-right: auto;
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 2px;
  padding-left: 0;
  text-align: left;
  width: 100%; }

.topmenu__toggle {
  display: none; }

.topmenu__hamburger {
  display: flex;
  height: 100%;
  width: 3rem;
  cursor: pointer;
  flex-direction: column;
  justify-content: center; }

.topmenu__button,
.topmenu__button::before,
.topmenu__button::after {
  display: block;
  background-color: #272b2f;
  position: absolute;
  height: 0.3rem;
  width: 3rem;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px; }

.topmenu__button::before {
  content: '';
  margin-top: -0.8rem; }

.topmenu__button::after {
  content: '';
  margin-top: 0.8rem; }

.topmenu__toggle:checked ~ .topmenu__list {
  display: block; }

.topmenu__toggle:checked + .topmenu__hamburger .topmenu__button {
  background: rgba(255, 255, 255, 0); }
  .topmenu__toggle:checked + .topmenu__hamburger .topmenu__button::before {
    margin-top: 0;
    transform: rotate(405deg); }
  .topmenu__toggle:checked + .topmenu__hamburger .topmenu__button:after {
    margin-top: 0;
    transform: rotate(-405deg); }

.topmenu__item {
  height: 0;
  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-bottom: 1px solid #fff; }

.topmenu__toggle:checked ~ .topmenu__list .topmenu__link {
  display: flex; }

.topmenu__toggle:checked ~ .topmenu__list .topmenu__item {
  height: 5.55rem; }

@media screen and (min-width: 768px) {
  .topmenu {
    height: 6rem; }
  .topmenu__list {
    display: flex;
    position: static;
    background-color: initial; }
  .topmenu__item {
    height: 100%; }
    .topmenu__item:hover .topmenu__link:before {
      transform: scale3d(1, 1, 1) translateX(-50%); }
  .topmenu__logo {
    width: 20rem; }
  .topmenu__link {
    display: block;
    padding: 3rem;
    color: #272b2f; }
    .topmenu__link:last-child {
      margin-right: -3rem; }
    .topmenu__link:before {
      position: absolute;
      background-color: #33b996;
      content: '';
      bottom: 2.5rem;
      left: 50%;
      width: 40%;
      height: 0.3rem;
      transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
      transform: scale3d(0, 1, 1) translateX(-50%);
      transform-origin: center center; }
    .topmenu__link--active:before {
      transform: scale3d(1, 1, 1) translateX(-50%);
      transition: none; }
  .topmenu__hamburger {
    display: none; } }

.bookgallery {
  display: flex;
  gap: 1rem;
  list-style: none;
  flex-direction: column;
  min-width: 20rem;
  padding: 2rem 0; }

.bookgallery__item {
  flex: 1;
  position: relative;
  cursor: pointer; }

.bookgallery__image {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; }

.bookgallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  color: #272b2f;
  width: 100%;
  padding: 2rem 0;
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 100;
  text-align: center;
  transform: translateY(100%);
  opacity: 0; }

@media (hover: hover) {
  .bookgallery__item:hover .bookgallery__caption {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.3s ease-in-out; } }

@media screen and (min-width: 768px) {
  .bookgallery {
    flex-direction: row;
    padding: 2rem; } }

@media screen and (min-width: 1020px) {
  .bookgallery {
    flex-direction: column;
    flex-basis: 20%;
    padding: 2rem 0; }
  .bookgallery__image {
    max-width: 24rem;
    max-height: 24rem; } }

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  align-items: center; }
  .modal::-webkit-scrollbar {
    width: 0px;
    background: transparent; }

.modal__img {
  opacity: 0;
  width: 0;
  transition: opacity 0.5s ease-in-out, width 0.1s ease;
  visibility: hidden;
  opacity: 0;
  max-width: 0;
  object-fit: contain; }
  .modal__img--visible {
    visibility: visible;
    opacity: 1;
    width: 100%;
    margin: auto;
    max-width: 100vw;
    max-height: 100vh; }

.modal__close {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  font-size: 2.5rem;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer; }

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  outline: 0;
  background-color: initial;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  padding: 0; }
  .prev svg,
  .next svg {
    fill: rgba(255, 255, 255, 0.5); }

.prev {
  left: 1rem;
  transform: translate(0, -50%) scaleX(-1); }

.next {
  right: 1rem; }

@media (hover: hover) {
  .prev:hover,
  .next:hover {
    background-color: rgba(255, 255, 255, 0.2); }
  .modal__img {
    display: none; }
    .modal__img--visible {
      display: block;
      max-width: 70vw;
      max-height: 90vh;
      width: auto; } }

@media screen and (min-width: 768px) {
  .prev,
  .next {
    height: 3rem;
    width: 3rem; }
  .prev {
    left: 2rem; }
  .next {
    right: 3rem; }
  .modal__close {
    top: 1rem;
    right: 2.5rem; } }

.navigation__prev,
.navigation__next {
  display: block;
  position: absolute;
  top: -1rem;
  z-index: 1;
  width: 4rem;
  height: 4rem;
  cursor: pointer; }
  .navigation__prev:before, .navigation__prev:after,
  .navigation__next:before,
  .navigation__next:after {
    content: '';
    position: absolute;
    top: 14px;
    right: 19px;
    width: 18px;
    height: 2px;
    background-color: #272b2f;
    transition: transform 0.3s; }

.navigation__prev {
  left: 1rem;
  transform: rotate(-90deg); }
  .navigation__prev:before {
    transform: translate3d(0px, 4px, 0px) rotate(-45deg); }
  .navigation__prev:after {
    transform: translate3d(12px, 4px, 0px) rotate(45deg); }

.navigation__next {
  right: 1rem;
  transform: rotate(-90deg); }
  .navigation__next:before {
    transform: translate3d(0px, 4px, 0px) rotate(45deg); }
  .navigation__next::after {
    transform: translate3d(12px, 4px, 0px) rotate(-45deg); }

.navigation__caption--prev,
.navigation__caption--next {
  display: none;
  position: absolute;
  white-space: nowrap;
  text-align: left;
  right: 4.5rem;
  top: 1.2rem;
  padding: 0 1rem;
  color: #272b2f;
  opacity: 0;
  visibility: hidden;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.5s; }

.navigation__caption--next {
  transform: rotate(180deg); }

@media screen and (min-width: 768px) {
  .article {
    padding: 0 20rem; }
  .navigation__prev,
  .navigation__next {
    top: 7rem; }
    .navigation__prev:hover .navigation__caption--prev,
    .navigation__next:hover .navigation__caption--prev {
      opacity: 1;
      visibility: visible;
      transform: translateX(-10px); }
    .navigation__prev:hover .navigation__caption--next,
    .navigation__next:hover .navigation__caption--next {
      opacity: 1;
      visibility: visible;
      transform: translateX(-10px) rotate(180deg); }
  .navigation__prev {
    left: 0;
    transform: rotate(-90deg); }
    .navigation__prev:hover::before {
      transform: translate3d(-1px, 4px, 0px) rotate(-25deg); }
    .navigation__prev:hover::after {
      transform: translate3d(13px, 4px, 0px) rotate(25deg); }
  .navigation__next {
    right: 0;
    transform: rotate(-90deg); }
    .navigation__next:hover::before {
      transform: translate3d(-1px, 4px, 0px) rotate(25deg); }
    .navigation__next:hover::after {
      transform: translate3d(13px, 4px, 0px) rotate(-25deg); }
  .navigation__caption--prev,
  .navigation__caption--next {
    display: block; } }

.posts__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 2rem;
  justify-content: space-between; }

.posts__figure {
  position: relative; }

.posts__item {
  width: 100%;
  height: 24rem; }

.posts__caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
  transition: opacity 0.4s ease-out; }

.posts__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle; }

.posts__date {
  opacity: 0;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0.5rem);
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  font-family: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  transition: transform 0.4s ease-out;
  font-weight: 100; }

.posts__link:hover .posts__caption {
  opacity: 0; }

.posts__link:hover .posts__date {
  opacity: 1;
  transform: translate(-50%, 0); }

@media screen and (min-width: 999px) {
  .posts {
    padding: 2rem; }
  .posts__item {
    flex: 1 0 30rem; } }

.page {
  display: flex;
  flex-wrap: wrap; }

.page__header {
  height: 38rem;
  background-color: #cfcfcf;
  background-position: center;
  background-size: cover;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 5rem;
  font-weight: 100;
  position: relative;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 10px; }

.about-img {
  background-image: url("../img/temp/about.jpg"); }

.book-img {
  background-image: url("https://res.cloudinary.com/dtqthf5kp/image/upload/v1664627101/in-dust-real/book/01_ebk98c.jpg"); }

.page__title {
  position: absolute;
  left: 3rem;
  transform: translateY(-50%);
  bottom: 2rem; }

.postgallery {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100vw; }

.postgallery__item {
  display: flex;
  cursor: pointer;
  flex: 1 0 100%; }

.postgallery__image {
  width: 100%;
  height: 26rem;
  object-fit: cover; }

.titleimg {
  flex: 1 0 auto;
  padding: 0 10rem;
  margin-bottom: 10rem; }
  .titleimg img {
    max-width: 100%;
    max-height: 100%;
    display: block; }

@media screen and (min-width: 768px) {
  .postgallery {
    padding: 2rem;
    width: auto; }
  .postgallery__item {
    flex: 1 0 auto; } }
